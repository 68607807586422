.container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  
  
  
}

.container__info {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;

}

.container__info__title {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #2F3567;

}


.box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 16px;
  background-color: #FFF4F0;
  padding: 16px;
  border-radius: 8px;


}

.box__desc {
  display: flex;
  align-items: center;
  column-gap: 8px;

}

.box__desc__text {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #2F3567;

}

.box__desc__bullet {
  width:8px;
  height: 8px;
  background-image: url(../../../assets/bullet.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.box__divider {
  background-color: #EFEFEF;
  height: 1px;
}