.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
}

.container__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;

}

.container__search {
  position: sticky;
  top: 0;
  padding: 12px 0;
  background-color: #ffff;
}


.container__routes {
  display: flex;
  justify-content: space-between;
}

.container__routes__btns {
  width: 130px;
  height: 52px;
  display: flex;
  justify-content: center;
  column-gap: 4px;
  align-items: center;
  padding: 14px;



  border-radius: 8px;
  border: 1px solid #E8E8E8;
}

.container__routes__btns__text {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 18.2px;
  color: #2F3567;

}




.container__box__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;

}


.box__colors__carousel {
  display: flex;
  overflow: hidden;

}


.box__colors__carousel__inner {
  display: flex;
  align-items: center;
  column-gap: 20px;

}

.container__bestSelling {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}