.box__card {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 4px;
  
  box-shadow: 0px 4px 16px 0px #00000014;
  padding: 12px
}

.box__content__filter__option {
  position: relative;
  padding-left: 28px;
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #2F3567;
  cursor: pointer;
  display: flex;
  column-gap: 14px;
  align-items: center;
}

.box__content__filter__option__input {
  display: none
}

.box__content__filter__option__label {
  position: absolute;
  left: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  outline: 1px solid #2F3567;
}

.box__content__filter__option input:checked~.box__content__filter__option__label {
  background-color: #FFFFFF;
  outline: 1px solid #FF6F3F;
}

/* .box__content__filter__option input:checked+.box__content__filter__labelText {
  color: #FF6F3F;

} */

.box__content__filter__option__label:after {
  content: "";
  position: absolute;
  transform: translate(36%, 36%);
  display: none;
}

.box__content__filter__option input:checked~.box__content__filter__option__label::after {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FF6F3F;
}

.box__content__filter__labelText__checked {
  color: #FF6F3F;
}

.box__card__info {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;
  padding-left: 28px;
}

.box__card__info__review {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;
  padding-left: 0;
  
}

.box__card__address {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.2px;
  color: #808080;
}

.box__card__confirm {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;
}

.box__card__confirm__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  border-radius: 8px;
  padding: 15px;
  background-color: #FF6F3F;
}
.box__card__navigate {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #4A4C5F;
  border-radius: 8px;
  border: 1px solid #DCDCDC;
  padding: 15px;
}

.box__card__delete {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FF6F3F;
  border-radius: 8px;
  border: 1px solid #DCDCDC;
  padding: 15px;
}