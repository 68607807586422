.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.container__outlet {
  padding: 12px 16px 100px;
}

.container__footer {
  position: fixed;
  bottom: 0;
  width: 100%;

}