.box {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.box__colors {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.box__colors__text {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #2F3567;
  font-family: "Poppins", sans;
  font-weight: 600;

}

.box__colors__carousel {
  display: flex;
  overflow: hidden;

}


.box__colors__carousel__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;

}


.box__content__filter {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  justify-content: center;
}

.box__content__filter__option {
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-left: 24px;
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  color: #2F3567;
  cursor: pointer;
}

.box__content__filter__option__input {
  display: none
}


.box__content__filter__option__label {
  position: absolute;
  left:2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  outline: 1px solid #2F3567;
  
}

.inActive {

  font-family: "Poppins", sans;
  font-weight: 400;
  color:#bdbcbc;

}

.box__content__filter__option input:checked~.box__content__filter__option__label {
  background-color: #FFFFFF;
  outline: 1px solid #FF6F3F;
}

.box__content__filter__option input:checked+.box__content__filter__labelText {
  color: #FF6F3F;

}



.box__content__filter__option__label:after {
  content: "";
  position: absolute;
  transform: translate(36%, 36%);
  display: none;
}

.box__content__filter__option input:checked~.box__content__filter__option__label::after {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FF6F3F;
}



.box__content__filter__labelText__checked {
  color: #FF6F3F;
}




.box__item__divider {
  background-color: #EFEFEF;
  height: 1.5px;
}


