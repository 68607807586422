.form__group {
  position: relative;
  padding: 15px 0 0;
  /* margin-top: 10px; */
}


.form__group__upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px dashed #00A267;
  padding: 12px 32px;
  background-color: #EDFEF8;
}

.form__field__upload__title {
  font-size: 16px;
  line-height: 16px;
  color: #9b9b9b;
  font-family: "Poppins", sans;
  font-weight: 400;
  padding-bottom: 12px;

}

.form__group__upload__box {
  position: relative;
  display: flex;
  flex-direction: column;

}




.form__field__upload__label {
  display: block;
  width: 111px;
  height: 24px;
  background-image: url("../../../assets/upload.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: pointer;

}


.form__field__upload {
  display: none;

}

.form__field__upload__showUrl {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #2F3567;
  font-family: "Poppins", sans;
  padding: 14px;
  background: transparent;
}

.form__field__upload__takePic__label {
  display: block;
  width: 111px;
  height: 24px;
  background-image: url("../../../assets/takePic.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  /* background-size: 111px 22px; */
  cursor: pointer;

}

.form__field__upload__takePic {
  display: none;
}



/* .form__field:focus {
  padding-bottom: 6px;
  

} */

.form__divider__line {

  height: 1.5px;
  background-color: #DDDDDD;
}

.form__divider {
  padding: 16px 0 12px;

}

.form__divider__container {
  position: relative;

}



.form__divider__textBox {
  position: absolute;
  top: -16px;
  left: 44%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 50%;
  background-color: #E6E6E6;
  align-items: center;

}

.form__divider__textBox__text {

  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  font-family: "Poppins", sans;
  color: #4A4C5F
}



.form__btn {
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  font-family: "Poppins", sans;
  background-color: #9C9C9C;
  padding: 18px 120px;
  border-radius: 4px;

}

.form__btn__enabled {
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  font-family: "Poppins", sans;
  background-color: #FF6F3F;
  padding: 18px 120px;
  border-radius: 4px;
  cursor: pointer;

}