.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.box {
  position: fixed;
  top: 40%;
  left: 16%;
  right: 16%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  transform: translate(16%, -50%);
  border-radius: 8px;
  background-color: #FFFFFF;
  /* z-index: 11; */
  overflow: hidden;
}

.box__content {
  display: flex;
  row-gap: 12px;
  flex-direction: column;
  justify-content: center;

}

.box__content__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #faf7f7;
  border-bottom: 1px solid #d6d6d6;
  padding: 16px 12px;

}

.box__content__head__title {
  font-family: "Poppins", sans;
  font-weight: 600;
  font-size: 12px;
  color: #2F3567;

}

.box__content__close {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../../assets/close_icon.svg);
  cursor: pointer;

}

.box__content__info {
  position: relative;
  display: flex;
  row-gap: 16px;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 0 12px;
}

.box__content__info__quest {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 12px;
  color: #FF6F3F;

}

.box__content__info__para {
  font-family: "Poppins", sans;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #6C6C6C;


}


.box__sep {
  width: 100%;
  border: 1px solid #d6d6d6;
}


.box__content__filter {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  justify-content: center;
}

.box__content__filter__option {
  position: relative;
  padding-left: 28px;
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #2F3567;
  cursor: pointer;
}

.box__content__filter__option__input {
  display: none
}

.box__content__filter__option__label {
  position: absolute;
  left: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  outline: 1px solid #2F3567;
}

.box__content__filter__option input:checked~.box__content__filter__option__label {
  background-color: #FFFFFF;
  outline: 1px solid #FF6F3F;
}

.box__content__filter__option input:checked+.box__content__filter__labelText {
  color: #FF6F3F;

}



.box__content__filter__option__label:after {
  content: "";
  position: absolute;
  transform: translate(36%, 36%);
  display: none;
}

.box__content__filter__option input:checked~.box__content__filter__option__label::after {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FF6F3F;
}



.box__content__filter__labelText__checked {
  color: #FF6F3F;
}

.box__content__textarea {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #6C6C6C;
  border: 1px solid #6C6C6C;
  border-radius: 8px;
  padding: 6px
}


.box__content__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;

}

.box__content__btn__confirm__enabled {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FFFF;
  border-radius: 8px;
  padding: 6px;
  background-color: #FF6F3F;

}

.box__content__btn__confirm {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6C6C6C;
  border: 1px solid #6C6C6C;
  border-radius: 8px;
  padding: 6px
}

.box__content__btn__cancel {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FFFF;
  border-radius: 8px;
  padding: 6px;
  background-color: #FF6F3F;

}