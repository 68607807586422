* {
  box-sizing: border-box;
  border: 0;
  margin: 0;
  text-decoration: none;
  padding: 0;
  outline: none;
}

button {
  background-color: transparent;
  cursor: pointer;
}


.multi-range-slider .thumb::before {
  width: 16px;
  height: 16px;
  border: 1px solid #C6C6C6;
  box-shadow: none;
  margin: -5px -12px ;
}

.multi-range-slider .bar-inner {
  border : none;
  box-shadow: none;
}

.multi-range-slider .bar-left {
  box-shadow: none;

}
.multi-range-slider .bar-right {
  box-shadow: none;

}
.multi-range-slider .thumb .caption * {
  box-shadow: none;
  background-color: #FF6F3F;

}



body {

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: env(safe-area-inset-top);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.hide-scroll {
  overflow: hidden;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}