.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
}

.container__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;

}

.container__search {
  position: sticky;
  top: 0;
  padding: 12px 0;
  background-color: #ffff;
}
