.login {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
}

.login__head {
  display: flex;
  flex-direction: column;
  /* row-gap: 12px; */

}

.login__head__title {
  font-family: "Poppins", sans;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #9C9C9C;
}


.login__head__subtitle {
  font-family: "Poppins", sans;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: #2F3567;

}


.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 88px;
}


.form__controls {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  /* margin-top: 10px; */
}

.form__field {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #2F3567;
  font-family: "Poppins", sans;
  /* padding: 7px 0; */
  padding: 14px 24px;
  background: transparent;
  /* transition: border-color 0.2s; */
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown~.form__label {
  font-size: 16px;
  cursor: text;
  top: 26px;
}

.form__label {
  font-family: "Poppins", sans;
  left: 12px;
  padding: 0 12px;
}

.form__label,
.form__field:focus~.form__label {
  position: absolute;
  top: 2px;
  display: block;
  transition: 0.2s;
  font-size: 16px;
  color: #9b9b9b;
  background-color: #ffff;
}

.form__field:focus~.form__label {
  color: #2F3567;
}

/* .form__field:focus {
  padding-bottom: 6px;
  

} */





.form__btn {
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  font-family: "Poppins", sans;
  background-color: #9C9C9C;
  padding: 18px 128px;
  border-radius: 4px;
  /* cursor: pointer; */

}

.form__btn__enabled {
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  font-family: "Poppins", sans;
  background-color: #FF6F3F;
  padding: 18px 128px;
  border-radius: 4px;
  cursor: pointer;

}