.box {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;
  border: 1px dashed #E5E5E5;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px #0000001A;
  padding: 16px 12px;
}

.box__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box__content__info {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;

}

.box__content__info__title {
  font-size: 16px;
  line-height: 20.8px;
  font-weight: 500;
  font-family: "Poppins", sans;
  color: #2F3567;
}

.box__content__info__subTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2px;
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 500;
  font-family: "Poppins", sans;
  color: #989898;
}

.box__content__info__subTitle__highLighted {
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 500;
  font-family: "Poppins", sans;
  color: #2F3567;

}

.box__content__btn {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  font-family: "Poppins", sans;
  border-radius: 4px;
  background-color: #FF6F3F;
  color: #FFFFFF;
  padding: 8px 12px;

}

.box__sep {
  border: 1px dashed #E5E5E5;
}

.box__content__info__para {
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #989898;

}