.box {
  /* min-width: 308px;  */
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
}

.slider {
  width: 100%;
  position: relative;
  height: 160px;
  overflow: hidden;
  border-radius: 4px;
}

.slide {
  position: absolute;
  height: 100%;
  width: 100%;
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  will-change: transform, opacity; */
  /* aspect-ratio: auto; */
  /* aspect-ratio: 82/51; */
  /* object-fit: contain; */
}


.indicators {
  display: flex;
  column-gap: 6px;
  justify-content: center;
}

.indicator {
  width: 6px;
  height: 4px;
  background: #000000;
  opacity: 0.2;

}


.isActive {
  background: #E45301;
  opacity: 1;

}