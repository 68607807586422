.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 32px;
}

.box__head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 16px;

}

.box__head__info {
  display: flex;
  align-items: center;
  column-gap: 10px;

}

.box__head__img {
  display: block;
  width: 20px;
  height: 20px;
}

.box__head__title {
  font-family: "Poppins", sans;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  color: #2F3567;
}

.box__head__para {
  font-family: "Poppins", sans;
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 400;
  color: #989898;

}

.box__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
}