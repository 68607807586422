.box__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  justify-content: center;

}

.box {
  display: flex;
  flex-direction: column;
  row-gap: 160px;
  justify-content: center;
}


.box__profile {
  height: 160px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-image: url(../../assets/profile__back.svg);
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  padding: 22px 16px 16px;
}

.box__profile__info {
  display: flex;
  flex-direction: column;
  row-gap: 64px;
  justify-content: center;


}

.box__profile__back {
  width: 24px;
  height: 24px;
  background-image: url(../../assets/arrow-left_lite.png);
  background-position: center;
  background-repeat: repeat;
  background-size: cover;

}

.box__profile__user {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.box__profile__info__avatar {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url(../../assets/avatar.svg);
  background-position: center;
  background-repeat: repeat;
  background-size: cover; */
  border-radius: 50%;
  background-color: #46CD80;
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;

}

.box__profile__info__name {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
}

.box__links {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  justify-content: center;
  margin-top: 164px;


}

.box__links__container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.box__link {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.box__link__wrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;

}

.box__link___image {
  display: block;
  width: 24px;
  height: 24px;
}

.box__link__title {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #2F3567;

}

.box__link__subTitle {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #989898;

}

.box__link__navigate__image {
  display: block;
  width: 20px;
  height: 20px;

}


.box__navigators__btn {
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 12px 108px;
  background-color: #AF3030;
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;

}

.box__btn {
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 16px 0px #0000001A;
  padding: 12px;
  border-radius: 8px;
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #FF6F3F;
}

.box__btn__img {
  width: 24px;
  height: 24px;
  background-image: url('../../assets/logOut.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.box__links__new {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #989898;

}

.box__brand {
  text-align: center;
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #989898;
}