.box {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;

}

.box__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;

}

.box__title {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #2F3567;
}

.box__price {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.box__container {
  display: flex;
  column-gap: 4px;
  align-items: center;

}

.box__quantity {
  display: flex;
  align-items: center;
  column-gap: 10px;

}

.box__price__discount {
  font-family: "Poppins", sans;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #FF6F3F;

}

.box__price__original {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #4A4C5F;
  position: relative;
  padding-right: 4px;

}

.box__price__original::before {
  content: "";
  position: absolute;
  bottom: 50%;
  right: 4%;
  width: 100%;
  height: 1px;
  background-color: #4A4C5F;
}


.box__price__discount__img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../../assets/discount_btn.svg);
  font-family: "Poppins", sans;
  font-weight: 600;
  font-size: 8px;
  line-height: 8px;
  color: #FFFFFF;
  padding: 2px 12px;

}




.box__info__qty {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.box__info__qty__label {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #595959;

}

.box__info__qty__input {
  width: 78px;
  border: 1px solid #EFEFEF;
  border-radius: 4px;
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #595959;
  padding: 7px 8px;

}

.box__divider {
  background-color: #EFEFEF;
  height: 1px;
}