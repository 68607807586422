.box {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.box__info {
  width: 100px;
  height: 40px;
  border: 1px solid #E5E4E2;
  border-radius: 4px;
  padding: 4px;
  background: linear-gradient(
    to right,
    #f0f0f0 0%,
    #dcdcdc 20%,
    #e0e0e0 40%,
    #dcdcdc 60%,
    #f0f0f0 80%,
    #f0f0f0 100%
  );
  background-size: 200% 100%;
  animation: loading 1.5s infinite linear;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
