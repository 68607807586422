.userProfile {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 32px;
}

.box__profile__back {
  width: 24px;
  height: 24px;
  background-image: url(../../assets/arrow-left.svg);
  background-position: center;
  background-repeat: repeat;
  background-size: cover;

}

.box__brand__wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 22px;

}

.profile {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
}

.profile__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile__head__title {
  font-family: "Poppins", sans;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: #2F3567;
}

.profile__head__status {
  align-self: center;
  font-family: "Poppins", sans;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 10px 8px;
  border-radius: 4px;

}


.profile__head__status__incomplete {
  color: #2F3567;
  background-color: #FFE8DF;
  border: 1px solid #FF6F3F;


}

.profile__head__status__pending {
  color: #FFFFFF;
  background-color: #FF6F3F;

}
.profile__head__status__completed {
  color: #FFFFFF;
  background-color: #46CD80;

}

.profile__head__status__rejected {
  color: #FFFFFF;
  background-color: #FE0000;

}