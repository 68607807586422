.box__card {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 16px 0px #00000014;
  padding: 12px
}

.box__card__info {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;
 
}


.box__card__address {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.2px;
  color: #808080;
}
