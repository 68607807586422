.box {
  display: flex;
  column-gap: 8px;
  align-items: center;
  cursor: pointer;
}



.box__btn {
  width: 24px;
  height: 24px;
  background-image: url('../../../assets/arrow-left.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

}

.box__search {
  flex-grow: 1;
}