.container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;

}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 12px;
  cursor: pointer;
}

.box__img {
  width: 30%;
}

.box__info {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 70%;
}

.box__info__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
  flex-grow: 1;

}

.box__info__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box__info__id {
  display: flex;
  justify-content: center;
  column-gap: 2px;
  align-items: center;

}

.box__info__id__title {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #989898
}

.box__info__id__number {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #2F3567;

}

.box__info__fav {
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../assets/heart.svg);
}

.box__info__fav__active {
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../assets/heart__filled.svg);
}

.box__info__quant {
  display: flex;
  column-gap: 2px;
  align-items: center;
}



.box__info__percent {
  display: flex;
  column-gap: 4px;
  align-items: center;

}

.box__discount {
  display: flex;
  column-gap: 2px;
  align-items: center;

}

.box__discount__container {
  display: flex;
  column-gap: 4px;
  justify-content: center;
  align-items: center;
}

.box__discount__container__price {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #4A4C5F;
  position: relative;

}

.box__discount__container__price::before {
  content: "";
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  height: 1.5px;
  background-color: #989898;
}


.box__discount__container__price__disc {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #FF6F3F;

}



.box__discount__img {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../assets/discount_btn.svg);
  font-family: "Poppins", sans;
  font-weight: 600;
  font-size: 8px;
  line-height: 8px;
  color: #FFFFFF;
  padding: 2px 12px
}

.box__info__btns {
  display: flex;
  column-gap: 16px;
  align-items: center;

}


.box__info__btns__cart {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  border-radius: 8px;
  border: 1px solid #FF6F3F;
  color: #FF6F3F;
  padding: 4px 11px;
  background-color: #FFFFFF;

}

.box__info__btns__buy {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  border-radius: 8px;
  border: 1px solid #FF6F3F;
  color: #FFFFFF;
  padding: 4px 20px;
  background-color: #FF6F3F;

}

.box__item__divider {
  background-color: #EFEFEF;
  height: 1.5px;
}