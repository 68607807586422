.box {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  justify-content: center;
}
.box__address {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;

}

.box__address__title {
  font-family: "Poppins", sans;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #2F3567;


}

.box__cart {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;

}

.box__cart__order {
  font-family: "Poppins", sans;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #2F3567;

}

.box__coupons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2F3567;
  border-radius: 8px;
  padding: 16px 12px;
}

.box__coupons__applied {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 16px 0px #00000014;
  border-radius: 8px;
  padding: 16px 12px;

}

.box__coupons__content {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
}

.box__coupons__content__img {
  width: 24px;
  height: 24px;
  background-image: url(../../assets/coupons.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}



.box__coupons__content__title {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 20.8px;
  color: #FAFAFA;

}

.box__coupons__content__applied__title {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  color: #2F3567;

}

.box__coupons__content__subTitle {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 18.2px;
  color: #2F3567;
}

.box__coupons__remove {
  font-size: 12px;
  line-height: 18.2px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #FE0000;

}





.box__coupons__navigate {
  width: 20px;
  height: 20px;
  background-image: url(../../assets/arrow__coupon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.box__cart__order__btn {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  font-family: "Poppins", sans;
  border-radius: 4px;
  background-color: #FF6F3F;
  color: #FFFFFF;
  padding: 18px;

}