.box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
  border-radius: 8px;
  border: 1px solid #E3E3E3;
  padding:14px 52px
}
.box__img {
  display: block;
  width: 40px;
  height: 48px;
}

.box__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
  text-align: center;
}

.box__content__text {
  font-family: "Poppins", sans;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  color: #FF6F3F;

}

.box__content__text__sub {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  color: #989898;

}
