.box {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;

}

.box__filter {
  
}


.box__itemList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 18px;
}