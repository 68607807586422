.box {
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  padding-bottom: 20px;

}

.box__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;
}

.box__wrapper__title {
  font-family: "Poppins", sans;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #2F3567;

}

.box__btn {
  width: 24px;
  height: 24px;
  background-image: url('../../assets/arrow-left.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  align-self: flex-start;

}


.box__card__new {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;
}

.box__card__new__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #2F3567;

  padding: 10px 12px;
  box-shadow: 0px 4px 16px 0px #00000014;

}

.box__card__new__right {
  width: 24px;
  height: 24px;
  background-image: url('../../assets/arrow-right-lite.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}


.box__btn__review {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  font-family: "Poppins", sans;
  border-radius: 4px;
  background-color: #FF6F3F;
  color: #FFFFFF;
  padding: 18px;

}