.auth {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 16px 20px;
  height: 100vh;
  row-gap: 88px;

}


