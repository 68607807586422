/* .container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #C6C6C6;
  padding: 12px 24px;
  border-radius: 12px;

} */

.spin {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}

.image {
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
}