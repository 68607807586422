.box {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  justify-content: center;
}

.box__cart {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;

}