.box {
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
}

.box__info {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  justify-content: center;
  text-align: center;

}

.box__info__title {
  font-family: "Poppins", sans;
  font-size: 16px;
  line-height: 20.8px;
  font-weight: 500;
  color: #2F3567;

}

.box__info__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: #FFFFFF;
  border-radius: 8px;
  background-color: #FF6F3F;
  padding: 15px 108px
}

.box__benefits__content__info__desc_1 {
  font-family: "Poppins", sans;
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 400;
  color: #2F3567;
}