.container {
  position: relative;
}


.container__input {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 4px 16px 0px #00000014;
  padding: 12px 32px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #2F3567;

}

.container__input::placeholder {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #BBBBBB;

}

.container__box {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  left: 12px;
  right: -12px
  
}

.container__box__img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}