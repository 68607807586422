.box__info__btns {
  display: flex;
  column-gap: 16px;
  align-items: center;

}


.box__info__btns__cart {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  border-radius: 8px;
  border: 1px solid #FF6F3F;
  color: #FF6F3F;
  padding: 4px 11px;
  background-color: #FFFFFF;

}

.box__info__btns__buy {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  border-radius: 8px;
  border: 1px solid #FF6F3F;
  color: #FFFFFF;
  padding: 4px 20px;
  background-color: #FF6F3F;

}


.box__btns__btn {
  font-size: 12px;
  line-height: 12px;
  font-family: "Poppins", sans;
  font-weight: 400;
  padding: 4px 11px;
  cursor: pointer;
}

.box__btns__add {
  border: 1px solid #FF6F3F;
  border-radius: 8px;
  color: #FF6F3F;
  background-color: #FFFFFF;

}
.box__btns__buy {
  border-radius: 8px;
  color: #FFFFFF;
  background-color: #FF6F3F;
}