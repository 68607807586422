.box {
  min-width: 308px; /* Minimum width */
  height: 176px; /* Fixed height */
  border-radius: 8px;
  overflow: hidden;
}

.box__img {
  width: 100%; 
  height: 100%; 
}