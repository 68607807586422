.box {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  box-shadow: 0px 4px 16px 0px #00000014;
  padding: 12px;
  border-radius: 4px;
}

.box__upper {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.box__upper__order {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;

}

.box__upper__order__info {
  display: flex;
  column-gap: 4px;
  align-items: center;
}

.box__upper__order__info__key {
  font-size: 16px;
  line-height: 18.2px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #989898;
}

.box__upper__order__info__value {
  font-size: 16px;
  line-height: 18.2px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #2F3567;
}


.box__upper__order__date {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: end;
}
.box__upper__order__date__text {
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #989898;

}

.box__expand {
  width: 20px;
  height: 20px;
  background-image: url('../../assets/chevron-down.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

}

.box__content {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
}

.box__content__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box__content__info__title {
  font-size: 14px;
  line-height: 18.2px;
  font-weight: 600;
  font-family: "Poppins", sans;
  color: #2F3567;

}

.box__content__info__box {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
}
.box__content__info__box__status {
  font-size: 10px;
  line-height: 13px;
  font-weight: 500;
  font-family: "Poppins", sans;
  color: #F2B200;
  padding: 8px;
  border-radius: 40px;
  background-color: #FBE8B3;
}


.box__return {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  
}

.box__return__info {
  display: flex;
  column-gap: 8px;
}

.box__return__info__img {
  display: block;
  width:16px;
  height: 16px;
}

.box__return__info__title {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #989898;

}

.box__sep {
  background-color: #EFEFEF;
  height: 1.5px;
}

.box__content__expand {
  width: 16px;
  height: 16px;
  background-image: url('../../assets/chevron-down.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  z-index: 8;
}
.box__content__detail {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
  align-items: center;
  padding: 20px;
}


.box__content__detail__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;

}

.box__content__detail__item__shipment {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 12px;
  align-items: center;
  background-color: #2F3567;
  padding: 8px 7px 8px;
}


.box__content__detail__item__info__shipment {
  font-size: 14px;
  line-height: 18.2px;
  font-weight: 600;
  font-family: "Poppins", sans;
  color: #FFFFFF;
}

.box__content__detail__item {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 12px;
  align-items: center;
}

.box__content__detail__item__info {
  font-size: 14px;
  line-height: 18.2px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #4A4C5F;

}


.box__sep {
  width: 100%;
  background-color: #EFEFEF;
  height: 1.5px;
}

.box__upper__order__info__key__amt {
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #4A4C5F;

}

.box__upper__order__info__value__amt {
  font-size: 14px;
  line-height: 18.2px;
  font-weight: 600;
  font-family: "Poppins", sans;
  color: #FF6F3F;

}

.box__downLoad {
  display: flex;
  column-gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #46CD80;
  border-radius: 4px;
  cursor: pointer;
}

.box__downLoad__icon {
  width: 14px;
  height: 14px;
  background-image: url('../../assets/downLoadIcon.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.box__downLoad__text {
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 600;
  font-family: "Poppins", sans;
  color: #FFFFFF;
}


.box__content__return {
  display: flex;
  column-gap: 4px;
  align-items: center;
  
}

.box__content__return__btn {
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #4A4C5F;
  border-radius: 20px;
  padding: 6px 9px;
  border: 1px solid #4A4C5F;

}

.box__content__return__img {
  width: 12px;
  height: 12px;
  background-image: url('../../assets/return_request.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.box__content__return__text {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #989898;

}