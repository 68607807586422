.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.box {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 16px;
  border: 1px solid #D3D3D3;
  background-color: #FFFFFF;
  padding: 12px;
  border-radius: 10px 10px 0 0;
}

.box__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 17px;
}

.box__content__head {
  display: flex;
  justify-content: space-between;
  align-items: center;


}

.box__content__head__title {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #2F3567;
}

.backdrop__btn {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../../../assets/close_icon.svg);
  cursor: pointer;

}

.box__content__divider {
  width: 100%;
  height: 1.5px;
  background-color: #EFEFEF;
}

.box__content__filter {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  justify-content: center;
}

.box__content__filter__option {
  position: relative;
  padding-left: 28px;
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #2F3567;
  cursor: pointer;
}

.box__content__filter__option__input {
  display: none
}

.box__content__filter__option__label {
  position: absolute;
  left: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  outline: 1px solid #2F3567;
}

.box__content__filter__option input:checked~.box__content__filter__option__label {
  background-color: #FFFFFF;
  outline: 1px solid #FF6F3F;
}

.box__content__filter__option input:checked+.box__content__filter__labelText {
  color: #FF6F3F;

}



.box__content__filter__option__label:after {
  content: "";
  position: absolute;
  transform: translate(36%, 36%);
  display: none;
}

.box__content__filter__option input:checked~.box__content__filter__option__label::after {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FF6F3F;
}



.box__content__filter__labelText__checked {
  color: #FF6F3F;
}


.box__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 36px;
}


.box__btn__clear {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #FE0000;
}

.box__btn__apply {
  color: #FFFFFF;
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  background-color: #FF6F3F;
  padding: 14px 52px;
  border-radius: 4px;
}

.box__content__filter__range {
  accent-color: #FF6F3F;
}

.box__content__filter__control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box__content__filter__control__input {
  width: 100px;
  height: 24px;
  padding: 6px 8px 6px 18px;
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #4A4C5F;
  border: 1px solid #C6C6C6;
}